import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const D3Visualization = () => {
  const svgRef: any = useRef(null);

  useEffect(() => {
    const numNodes = 180; // total nodes
    const maxDist = 120; // max distance to connect edges
    const speedFactor = 2.8; // global speed multiplier

    let width = window.innerWidth;
    let height = window.innerHeight;

    // Select the SVG
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Groups for edges and nodes
    const linkGroup = svg.append("g").attr("class", "links");
    const nodeGroup = svg.append("g").attr("class", "nodes");

    // Edge opacity scale
    const edgeOpacityScale = d3
      .scaleLinear()
      .domain([0, maxDist])
      .range([1, 0.1]);

    // Generate nodes
    const nodes = d3.range(numNodes).map(() => {
      const r = 2 + Math.random() * 4; // random radius [2..8]
      const velocityScale = 8 / r; // smaller => faster
      return {
        x: Math.random() * width,
        y: Math.random() * height,
        r: r,
        vx: (Math.random() - 0.5) * 0.5 * velocityScale * speedFactor,
        vy: (Math.random() - 0.5) * 0.5 * velocityScale * speedFactor,
      };
    });

    const nodeSel = nodeGroup
      .selectAll("circle")
      .data(nodes)
      .enter()
      .append("circle")
      .attr("fill", "#007acc")
    //   .attr("stroke", "#007aee")
      .attr("stroke-width", 1);

    // Compute edges
    const computeEdges = (nodes:any, maxDist:any) => {
      const edges = [];
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const dist = Math.sqrt(dx * dx + dy * dy);
          if (dist < maxDist) {
            edges.push({ source: i, target: j, dist: dist });
          }
        }
      }
      return edges;
    };

    // Main animation loop
    d3.timer(() => {
      // Move nodes
      nodes.forEach((d: any) => {
        d.x += d.vx;
        d.y += d.vy;
        if (d.x < 0 || d.x > width) d.vx *= -1;
        if (d.y < 0 || d.y > height) d.vy *= -1;
      });

      // Recompute edges
      const edges = computeEdges(nodes, maxDist);

      // Calculate node degrees
      const nodeDegree = new Array(numNodes).fill(0);
      edges.forEach((e) => {
        nodeDegree[e.source]++;
        nodeDegree[e.target]++;
      });
      const maxDegree = d3.max(nodeDegree);

      // Remove old lines
      linkGroup.selectAll("line").remove();

      // Create new lines
      linkGroup
        .selectAll("line")
        .data(edges)
        .enter()
        .append("line")
        .attr("x1", (d:any) => nodes[d.source].x)
        .attr("y1", (d:any) => nodes[d.source].y)
        .attr("x2", (d:any) => nodes[d.target].x)
        .attr("y2", (d:any) => nodes[d.target].y)
        .attr("stroke-opacity", (d:any) => edgeOpacityScale(d.dist));

      // Update node positions
      nodeSel
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y)
        .attr("r", (d: any, i:any) => {
          if (nodeDegree[i] === maxDegree) {
            return Math.max(d.r, 10);
          }
          return d.r;
        })
        .attr("fill", (d:any, i:any) => {
          return nodeDegree[i] === maxDegree ? "#8A1115" : "#007acc";
        });
    });

    // Handle window resize
    const handleResize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      svg.attr("width", width).attr("height", height);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      svg.selectAll("*").remove();
    };
  }, []);

  return <svg className="aifeel" ref={svgRef} />;
};

export default D3Visualization;