import { setLSPREFIX } from "../../footer/utilities/Functions/lsPrefix";
import { redirectToLogin } from "../../footer/utilities/Functions/redirectToLogin";

export const COLOR_MAP: any = {
    "Done": "#28a745",
    "In progress": "#FFC300",
    "Deferred": "#FD4D58",
    "New": "#A7A8AA",
    "Not started": "#4F4F4F",
    "--": "#E3EDF1"
};

export const RECURRING_PERIODS: Array<any> = [
    { id: 'years', name: 'Yearly' },
    { id: 'months', name: 'Monthly' },
    { id: 'weeks', name: 'Weekly' },
    { id: 'days', name: 'Daily' },
    { id: 'hours', name: 'Hourly' },
    // {id:'minutes',name:'Minutes'},
]

export const RECURRING_INTERVAL_LOOKUP: any = {
    years: "Yearly",
    months: "Monthly",
    weeks: "Weekly",
    days: "Daily",
    hours: "Hourly",
    minutes: "Minutes",
    cycles: 'Cycles'
};

export const POWER: any = {
    l1_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: true, tasks: true },
    l2_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: true, tasks: true },
    l3_user: { dashboard: true, status: false, incidents: true, assets: false, reports: false, advanced: true, tasks: true },
    l4_user: { dashboard: true, status: false, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    l5_user: { dashboard: true, status: false, incidents: false, assets: false, reports: false, advanced: false, tasks: true },
    l6_user: { dashboard: true, status: false, incidents: false, assets: false, reports: false, advanced: false, tasks: true },
    l7_user: { dashboard: true, status: false, incidents: false, assets: false, reports: false, advanced: false, tasks: true },
    power_admin: { dashboard: true, status: false, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }
}

export const COOLING: any = {
    l1_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: true, tasks: true },
    l2_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: true, tasks: true },
    l3_user: { dashboard: true, status: false, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    cooling_admin: { dashboard: true, status: false, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }

}

export const CHEMICALS: any = {
    l1_user: { dashboard: true, status: true, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    l2_user: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    l3_user: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    l5_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: false, tasks: true },
    chemicals_admin: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }

}

export const WWS: any = {
    l1_user: { dashboard: true, status: true, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    l2_user: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    l3_user: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    l5_user: { dashboard: true, status: false, incidents: false, assets: false, reports: true, advanced: false, tasks: true },
    wws_admin: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }

}

export const TBWES: any = {
    l1_user: { dashboard: true, status: true, incidents: true, assets: false, reports: true, advanced: true, tasks: true },
    l2_user: { dashboard: true, status: true, incidents: false, assets: true, reports: false, advanced: true, tasks: true },
    l3_user: { dashboard: true, status: true, incidents: true, assets: true, reports: false, advanced: true, tasks: true },
    l5_user: { dashboard: true, status: false, incidents: false, assets: false, reports: false, advanced: false, tasks: true },
    tbwes_admin: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }

}

export const HEATING: any = {
    l9_user: { dashboard: true, status: false, incidents: true, assets: false, reports: false, advanced: true, tasks: true },
    l10_user: { dashboard: true, status: false, incidents: true, assets: false, reports: false, advanced: true, tasks: true },
    l16_user: { dashboard: true, status: false, incidents: true, assets: false, reports: false, advanced: true, tasks: true },
    testing: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    exp_users: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true },
    heating_admin: { dashboard: true, status: true, incidents: true, assets: true, reports: true, advanced: true, tasks: true }

}
export const PRIORITY_COLOR_MAP: any = {
    High: "#FF2E19",
    Medium: "#FF7119",
    Low: "#FFDC19",
    "--": "#E3EDF1"
};

export const PRIORITY_MAP_LOOKUP: any = {
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    Critical: "Critical",
    High: "High",
    Medium: "Medium",
    Low: "Low",
};

export const STATUS_LIST = ["Not started", "In progress", "Done", "Deferred"]

export const PRIORITY_LIST = ["High", "Medium", "Low"]

export const TEAM_LOOKUP = [
    "Operations",
    "Maintenance",
    "C&I",
    "Electrical",
    "Management",
    "Lab",
    "Mechanical Maintenance",
    "C&I Maintenance",
    "Electrical Maintenance",
    "Technical Cell"
];


export const COLORMAP: any = {
    critical: "#DC120A",
    warning: "#FF9338",
    deviation: "#FFD542",
    healthy: "#2C7D0F",
};

export const USER_FEATURE : any = {
    'profileAccess' : 'profile'
}

export const USER_CONFIG: any = {
    "/#/map":"status",
    "/#/fleet":"fleet",
    "/pulse-master/my-tasks":"tasks",
    "/pulse-master/profiles":"profile",
    "/pulse-master/create-task":"tasks",
    "/pulse-master/custom-tasks":"tasks",
    "/pulse-master/plots":"incidents",
    "/#/incidents":"incidents",
    "/#/unit":"assets",
    "/#/reports":"reports",
    "/#/advanced":"advanced",
    "/pulse-ui-v2/#/analyze":"analyze",
    "/pulse-failures":"pulse-failures",
    "/#/performance":"performance",
    "/pulse-ui-v2/#/unit":"admin",
    "/pulse-ui-v2/#/faulttemplates":"faulttemplates",
    "/pulse-ui-v2/#/faulttrees":"faulttrees",
    "/pulse-ui-v2/#/onboarding":"onboarding",
    "/#/assetmanager":"assetmanager",
    "/pulse-ui-v2/#/calculation":"calculation",
    "/#/data":"dataentry",
    "/#/unitoverview":"unitoverview",
    "/pulse-ui-v2/#/faulttree":"diagnose",
    "/pulse-ui-v2/#/init":"datalink",
    "/pulse-ui-v2/#/modelpipeline":"models",
    "/pulse-fuel-tco":"fueltcoAutoAnalyser",
    "/pulse-elog":"elog",
    "/pulse-elog/logbook":"elog",
    "/#/fueltco":"fueltco",
    "/pulse-ui-v2/#/designcurves":"design",
    "/#/reportgenerator":"reportgenerator",
    "/rotary-assets":"rotaryassets",
    "/pulse-mgmt-dashboard":"management",
    "/#/teams":"teams",
    "/pulse-boilers":"boilers",
    "/pulse-asset-manager":"boilers",
    "/pulse-incidents-timeline":"incidentTimeline",
    "/pulse-ui-v2/#/manageuser":"manageuser",
    "/#/externaluser":"externaluser",
    "/pulse-overview/fleet":"pulse-overview",
    "/pulse-overview/unit":"pulse-overview",
    "/pulse-overview/dashboard":"pulse-overview-dashboard",
    "/pulse-reports-builder":"report-builder",
    "/pulse-elog/logbooks":"elog",
    // FOR ES
    "/pulse-ops-centre":"opscenter",
    '/pulse-fleet-ng/#/fleet':"fleet",
    '/pulse-configurations':"pulse-configurations",
    '/feed-alerts':"feedAlerts",
}

export const PULSE_DOCS = {
    "/#/map":"https://pulsedocs.exactspace.co/en/viewing-current-status-of-systems-equipment-and-sensors",
    "/#/fleet":"https://pulsedocs.exactspace.co/en/Fleet-Monitoring",
    "/pulse-master/my-tasks":"https://pulsedocs.exactspace.co/en/task-management-task-engine",
    "/#/incidents":"https://pulsedocs.exactspace.co/en/incident-(alarms)-engine",
    "/#/unit":"https://pulsedocs.exactspace.co/en/asset-management",
    "/#/reports":"https://pulsedocs.exactspace.co/en/reports",
    "/pulse-ui-v2/#/analyze":"https://pulsedocs.exactspace.co/en/analyze-visualization",
    "/#/assetmanager":"https://pulsedocs.exactspace.co/en/asset-management",
    "/#/data":"https://pulsedocs.exactspace.co/en/data-entry",
    "/#/unitoverview":"https://pulsedocs.exactspace.co/en/unit-overview",
    "/rotary-assets":"https://pulsedocs.exactspace.co/en/asset-monitor",
    "/pulse-ops-center":"https://pulsedocs.exactspace.co/en/Dashboard",
    "/#/tagmapper":"https://pulsedocs.exactspace.co/en/sensor-tag-management",
    "/#/apcmanager":"https://pulsedocs.exactspace.co/en/apc-manager",
    "/#/configureasset":"https://pulsedocs.exactspace.co/en/configuring-assets",
    "/#/combustion":"https://pulsedocs.exactspace.co/en/optimising-process",
    "/#/teams":"https://pulsedocs.exactspace.co/en/team-management",
    "default": "https://pulsedocs.exactspace.co/en/Introduciton"
}

export function removeDomain(url: string): string {
    try {
        const parsedUrl = new URL(url);
        // Combine pathname and hash
        let basePath = parsedUrl.pathname;
        if (parsedUrl.hash) {
            // Include hash without query parameters
            basePath += parsedUrl.hash.split('?')[0];
        }
        return basePath;
    } catch (error) {
        console.error("Invalid URL provided:", error);
        return ""; // Return an empty string or handle it as per your needs
    }
}

export const LS_PREFIX = setLSPREFIX() as string;
export const getLocalStorageItem = <T>(key: string, defaultValue: T): T => {
    try {
      const item = localStorage.getItem(`${LS_PREFIX}.${key}`);
      return item ? JSON.parse(item) : defaultValue;
    } catch {
      return defaultValue;
    }
  };

  export const checkIsGuestUser = (): boolean => {
    let basePath = getBasePath(window.location.href);
    const baseRepo = USER_CONFIG[basePath];
    try {
      const accessObject = getLocalStorageItem('accessObject', {});
      const selectedBu = getLocalStorageItem('selectedBu', '');
      const profile: { department: string } = getLocalStorageItem('profile', { department: '' });
  
      if (!selectedBu || !profile.department) {
        return false;
      }
      const accessValue = accessObject?.[selectedBu]?.[profile.department]?.[baseRepo];
      return accessValue === 'guest' || accessValue === 'noaccess';
      
    } catch {
      return false;
    }
  };
  export const getUserAccessLevel = (): boolean => {
    let basePath = getBasePath(window.location.href);
    const baseRepo = USER_CONFIG[basePath];
    try {
      const accessObject = getLocalStorageItem('accessObject', {});
      const selectedBu = getLocalStorageItem('selectedBu', '');
      const profile: { department: string } = getLocalStorageItem('profile', { department: '' });
  
      if (!selectedBu || !profile.department) {
        return false;
      }
      const accessValue = accessObject?.[selectedBu]?.[profile.department]?.[baseRepo];
      return accessValue;
      
    } catch {
      return false;
    }
  };
  export const checkIsAdminUser = (): boolean => {
    let basePath = getBasePath(window.location.href);
    const baseRepo = USER_CONFIG[basePath];
    try {
      const accessObject = getLocalStorageItem('accessObject', {});
      const selectedBu = getLocalStorageItem('selectedBu', '');
      const profile: { department: string } = getLocalStorageItem('profile', { department: '' });
  
      if (!selectedBu || !profile.department) {
        return false;
      }
      const accessValue = accessObject?.[selectedBu]?.[profile.department]?.[baseRepo];
      return accessValue === 'admin';
      
    } catch {
      return false;
    }
  };

  export const getBasePath = (currentUrl:any)=>{
    let basePath = removeDomain(currentUrl);
    if(basePath.includes("/pulse-master/my-tasks")){
        basePath = "/pulse-master/my-tasks"
    }
    if(basePath.includes("/pulse-elog/logbooks")){
        basePath = "/pulse-elog/logbooks"
    }
    if(basePath.includes("/pulse-boilers")){
        basePath = "/pulse-boilers"
    }
    if(basePath.includes("/pulse-asset-manager")){
        basePath = "/pulse-asset-manager"
    }
    if(basePath.includes("/rotary-assets")){
        basePath = "/rotary-assets"
    }
    if (basePath.endsWith('/')) {
        basePath = basePath.slice(0, -1); 
    }
    return basePath;
  }

  let BASE_URL: any;
  if (window.location.host.split(':')[0] === "localhost") {
      BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
  } else {
      BASE_URL = window.location.origin;
  }

  export const isUrlNotAllowed = (url: string) => {
    try {
        const LS_PREFIX = setLSPREFIX() as string;
        let profileDataLocal: any = localStorage.getItem(LS_PREFIX + ".profile");
        profileDataLocal = JSON.parse(profileDataLocal);
        let accessObject: any = localStorage.getItem(LS_PREFIX + ".accessObject");
        accessObject = JSON.parse(accessObject);
        if(!accessObject){
            console.log("accessObject not found")
            return true;
        }
        let user: any = JSON.parse(localStorage.getItem(LS_PREFIX + ".user") as any);
        let USER_BU = user.bu[0];
        const currentUrl= url.split('?')[0];
        let basePath = getBasePath(currentUrl)
        console.log(basePath,'basePathbasePath',currentUrl,url)
        const userRole = accessObject[USER_BU][profileDataLocal['department']][USER_CONFIG[basePath]];

        let redirectionURL: any = localStorage.getItem(LS_PREFIX + ".redirectionURL");

        if((redirectionURL == basePath) && (userRole === "noaccess" || userRole === "") ){
            localStorage.clear();
            let anc = document.createElement("a");
            anc.setAttribute("href", `${BASE_URL}/pulse-login`);
            anc.click();
        }
        // if role noaccess or empty returns true
        console.log(userRole,'userRoleuserRole')
        return userRole === "noaccess" || userRole === "";
    } catch (e: unknown) { // Use 'unknown' for the error type
        if (e instanceof Error) {
            // Handle Error objects
            console.error("Error message for accessObject:", e.message);
        } else {
            // Handle other types of errors
            console.error("Unknown error for accessObject:", e);
        }
        return true;
    }
}

export const isFeatureNotAllowed = (feature: string) => {
    try {
        const LS_PREFIX = setLSPREFIX() as string;
        let profileDataLocal: any = localStorage.getItem(LS_PREFIX + ".profile");
        profileDataLocal = JSON.parse(profileDataLocal);
        let accessObject: any = localStorage.getItem(LS_PREFIX + ".accessObject");
        accessObject = JSON.parse(accessObject);
        if (!accessObject) {
            console.log("accessObject not found")
            return true;
        }
        let user: any = JSON.parse(localStorage.getItem(LS_PREFIX + ".user") as any);
        let USER_BU = user.bu[0];
        const userRole = accessObject[USER_BU][profileDataLocal['department']][USER_FEATURE[feature]];

        // if role noaccess or empty returns true
        console.log(userRole, 'userRoleuserRole')
        return userRole === "noaccess" || userRole === "";
    } catch (e: unknown) { // Use 'unknown' for the error type
        if (e instanceof Error) {
            // Handle Error objects
            console.error("Error message for accessObject:", e.message);
        } else {
            // Handle other types of errors
            console.error("Unknown error for accessObject:", e);
        }
        return true;
    }
}
export const confirmation_messages:any= {
    "Acknowledge":"Are you sure you want to acknowledge this ?",
    "Handover":"Are you sure you want to Hand over this ?",
    "Approve":"Can you confirm the Approval ?",
    "Takeover":"Can you confirm the takeover request ?",
    "Delete":"This will permanently delete the component. This Logbook will no longer be accessible to you or anyone else. This action is irreversible."
  }