import axios from "axios";
import { setLSPREFIX } from "./lsPrefix";
import { API_HEADERS } from "../../../../config/global_config";
import { redirectToLogin } from "./redirectToLogin";
import { logError } from "./errorLogger";

/**
 * @author Ravi Sankar
 * @param endpoint
 * @param method
 * @param headers
 * @param payload
 * API request handler
 */


// Create a shared Axios instance
const api = axios.create({
    baseURL: "", // Set your base URL here, e.g., "https://example.com/api"
    headers: {
        ...API_HEADERS, // Add default headers from your global config
    },
});

// Add an interceptor for response error handling
api.interceptors.response.use(
    (response) => response, // Pass responses through without changes
    (error) => {
        if (error.response && error.response.status === 401) {
            // redirectToLogin();
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);


function getAccessToken(): string {
    try {
        const LS_PREFIX = setLSPREFIX(true); // Retrieve the local storage prefix
        let token = null
        try {
            const queryString = window.location.search;
            const searchParams: any = new URLSearchParams(queryString);
            const parsedFilter = JSON.parse(searchParams.get('filter'));
            // console.log("accesstoken: ", parsedFilter['access_token'])
            if (parsedFilter['access_token']) {
                token = parsedFilter['access_token']
            } else {
                token = localStorage.getItem(`${LS_PREFIX}.access_token`)?.replace(/"/g, "") || "";
            }
        } catch (err) {
            token = localStorage.getItem(`${LS_PREFIX}.access_token`)?.replace(/"/g, "") || "";
        }
        return token
    } catch (err) {
        console.error("Error retrieving access token:", err);
        return "";
    }
}
export async function api_request(endpoint: string, method: string, payload = {}, headers = {}) {
    // Set the Authorization header
    let defaultHeaders = null
    if (Object.keys(headers)?.length) {
        defaultHeaders = headers
    } else {
        const accessToken = getAccessToken();
        defaultHeaders = {
            Authorization: `Bearer ${accessToken}`,
            ...headers,
        };
    }

    try {
        let response;

        switch (method.toLowerCase()) {
            case "get":
                response = await axios.get(endpoint, { headers: defaultHeaders });
                break;
            case "post":
                response = await axios.post(endpoint, payload, { headers: defaultHeaders });
                break;
            case "delete":
                response = await axios.delete(endpoint, { headers: defaultHeaders });
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }

        return response;
    } catch (error) {
        // Narrow the error type to AxiosError or Error
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
                console.log("Unauthorized! Redirecting to login.");
                // redirectToLogin()
            } else {
                logError(error);
            }   
        } else if (error instanceof Error) {
            logError(error);
        } else {
            console.error("Unexpected axio error type:", error);
        }
    }
}
