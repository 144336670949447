import { useFormik } from "formik";
import { useEffect, useState } from "react";
import bhelLogo from "../assets/images/bhelupdated.jpg";
import Loader from "react-loader";
import { useNavigate } from "react-router-dom";
import { api_request } from "../layout/footer/utilities/Functions/APILayer";
import D3Visualization from "./D3Visualization";
import { logEvent,logException } from "../layout/footer/utilities/Functions/ApplicationInsightLogger";
import { isUrlNotAllowed, USER_CONFIG } from "../layout/navbar/config/config";
import LogoView from "../components/LogoView";
import TwoFactorAuth from "./TwoFactorAuth";


const Login = () => {
    useEffect(() => {
        api_request(`${BASE_URL}/ls-prefix`, "get")
            .then((response: any) => {
                setLSPrefix(response.data[0]["LS_PREFIX"])
            })
    }, [])

    const validateEmail = (email: any) => {
        // Validating if email is present and valid 
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!email) {
            return "Email is required";
        } else if (!emailRegex.test(email)) {
            return "Invalid email address";
        }
        return null;
    };

    const validatePassword = (password: any) => {
        // Presently only validating if password is empty or not. 
        if (!password) {
            return "Password is required";
        }
        return null;
    };

    const navigate = useNavigate();
    let BASE_URL: any;
    let selectedUnitId: any;
    let customerAllowed: any;
    if (process.env.REACT_APP_MODE === "test") {
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    } else {
        BASE_URL = window.location.origin;
    }

    const [LS_PREFIX, setLSPrefix] = useState("");

    if (LS_PREFIX === 'bhel') {
        document.title = 'RMDS'
    }
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [selectedUnitStored, setSelectedUnitStored] = useState<Boolean>(false);
    const [custAllowedStored, setCustAllowedStored] = useState<Boolean>(false);
    const [profileStored, setProfileStored] = useState<Boolean>(false);
    const [accessObjStored, setaccessObjStored] = useState<Boolean>(false);
    const [is2FA, setIs2FA] = useState<Boolean>(false);
    const [is2FACodeRequired, setIs2FACodeRequired] = useState<Boolean>(false);
    const [sessionId, setSessionId] = useState<string>('');
    const [redirectionURL,setRedirectionURL] = useState<any>();
    const [redirectionURLAccess,setRedirectionURLAccess] = useState<boolean>(true);

    const [recordedUserActivity, setRecordedUserActivity] = useState<Boolean>(false);
    type UserActivityProps = {
        activity: string,
        time: string,
        platform: string,
        email: string
    }
    const record_user_activity = (email: string, accesstoken: any) => {
        const user_activities_url = BASE_URL + `/exactapi/useractivities`
        const headers = {"Authorization":`Bearer ${accesstoken}`}
        const currentTime = new Date();
        const year = currentTime.getFullYear();
        const month = String(currentTime.getMonth() + 1).padStart(2, '0');
        const day = String(currentTime.getDate()).padStart(2, '0');
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        const seconds = String(currentTime.getSeconds()).padStart(2, '0');

        const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        let user_activities_payload: UserActivityProps = {
            "activity": "login",
            time: formattedTime,
            platform: "web",
            email: email
        }
        api_request(user_activities_url, "post", user_activities_payload,headers).then(
            () => {
                setRecordedUserActivity(true);
            }
        ).catch(
            (err: any) => {
                // Avoiding user login getting blocked due to api failure (local storage is already set at this point).
                setRecordedUserActivity(true);
                console.log(err)
            }
        )

    }

    // useEffect(() => {

    //     // 1. WHAT TO DO, IF API FAILS ?
    //     const checkForTwoFactorAuthentication = () => {
    //         const url = `${BASE_URL}/exactapi/configs?filter={"limit":1,"fields":["2fa"]}`;
    //         api_request(url, "GET").then((response: any) => {  
    //             try {
    //                 setIs2FA(response.data?.[0]?.['2fa']);
    //             }catch (err){
    //                 setIs2FA(false);
    //             }
    //         }).catch((e: Error) => {
    //             setIs2FA(false);
    //         })
    //     }

    //     checkForTwoFactorAuthentication();
    // },[])

    const handleLoginUser = (accesstoken: string, email: string) => {
        const get_unit_api = BASE_URL + "/exactapi/units/";
        const get_customer_relation_api = BASE_URL + `/exactapi/customers/alter`;
        const headers = {"Authorization":`Bearer ${accesstoken}`}
        const get_userprofile_api = `${BASE_URL}/exactapi/userprofiles?filter={"where":{"email":"${email}"}}`;
        api_request(get_userprofile_api, "GET",undefined,headers).then((response: any) => {
            localStorage.setItem(LS_PREFIX + ".user", JSON.stringify(response.data[0]));
            localStorage.setItem(LS_PREFIX + ".profile", JSON.stringify(response.data[0]));
            localStorage.setItem(LS_PREFIX + ".userToken", JSON.stringify(accesstoken));

            localStorage.setItem(LS_PREFIX + ".selectedCustomerid", JSON.stringify(response.data[0]["accessAllowed"][0]["customerId"]));
            localStorage.setItem(LS_PREFIX + ".selectedSiteid", JSON.stringify(response.data[0]["accessAllowed"][0]["sitesId"]));
            localStorage.setItem("lsprefix", JSON.stringify(LS_PREFIX));
            let valueuser: any = response.data[0]
 
            let accessObj: any = {};
            api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"bu", "name":{"inq":${JSON.stringify(valueuser.bu)}}}}`, "get",undefined,headers).then((resBu: any) => {
                api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"role", "name":"${valueuser.department}"}}`, "get",undefined,headers).then((resRole: any) => {
                    api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"feature"}}`, "get",undefined,headers).then((resFeatures: any) => {
                        const bu = resBu.data; //[{"type":"qa","name":"oil_and_gas"},{"type":"qa","name":"power"}]
                        const roles = resRole.data;
                        const features = resFeatures.data;
// console.log(bu,roles,features,'ressssss'); 

                        if(roles[0]?.redirectionURL){
                            setRedirectionURL(roles[0]?.redirectionURL);
                            localStorage.setItem(LS_PREFIX + ".redirectionURL", roles[0]?.redirectionURL);
                        }
      
                        bu.forEach((b: any) => {
                            accessObj[b.name] = {};
                            roles.forEach((role: any) => {
                                accessObj[b.name][role.name] = {};
                                features.forEach((feature: any) => {
                                    accessObj[b.name][role.name][feature.name] = ""
                                })
                            })
                        })

                        api_request(`${BASE_URL}/exactapi/configurations?filter={"where":{"type":"policy"}}`, "get",undefined,headers).then((resPolicy: any) => {
                            const policies = resPolicy.data;
                            features.forEach((f: any) => {
                                bu.forEach((b: any) => {
                                    roles.forEach((r: any) => {
                                        policies.forEach((p: any) => {
                                            let result = policies.find((item: any) => (item.featureId === f.id) && (item.roleId === r.id) && (item.buId == b.id));
                                            if (result == undefined) {
                                                accessObj[b.name][r.name][f.name] = ""
                                            } else {                     
                                                accessObj[b.name][r.name][f.name] = result.access
                                            }
                                        })
                                        // let result = policies.find((item :any)=> (item.featureId === f.id) && (item.roleId === r.id));
                                        // console.log(result)
                                        // if(result["id"] === "662f3a62b2a6f300079bcd86"){
                                        //     console.log("objeeee", result)
                                        // }
                                        // if(result == undefined){
                                        //     accessObj[b.name][r.name][f.name] = ""
                                        // }else{
                                        //     accessObj[b.name][r.name][f.name]= result.access
                                        // }
                                    })
                                })
                            })
                            
                            if(accessObj?.[valueuser?.bu?.[0]]?.[valueuser?.['department']]?.[USER_CONFIG?.[roles?.[0]?.redirectionURL]]){
                                const userRole = accessObj[valueuser.bu[0]][valueuser['department']][USER_CONFIG[roles[0]?.redirectionURL]]
                                if(userRole=="noaccess" || userRole==""){
                                    setRedirectionURLAccess(false)
                                }
                            }
                            
                            localStorage.setItem(LS_PREFIX + '.accessObject', JSON.stringify(accessObj));
                            setaccessObjStored(true);
                        })
                    })
                })

            })

            for (let accessAllowed of response.data[0]["accessAllowed"]) {
                if (accessAllowed["units"].length > 0) {
                    selectedUnitId = JSON.stringify(accessAllowed["units"][0])
                    localStorage.setItem(LS_PREFIX + ".selectedUnitid", selectedUnitId);
                    break;
                }
            }
            const selectedUnitList = response.data[0][
                'accessAllowed'
            ].filter((item: any) => {
                if (item['units'].length > 0) return true;
                else return false;
            });
            if (selectedUnitList.length === 0) setError(
                "Login failed"
            );
            api_request(get_unit_api + selectedUnitList[0]['units'][0], "GET",undefined,headers).then((response: any) => {
                localStorage.setItem(LS_PREFIX + ".selectedUnit", JSON.stringify(response.data));
                try{

                    let selectedBu = JSON.stringify(response.data['bu'][0]);
                    localStorage.setItem(LS_PREFIX + ".selectedBu",selectedBu);
                }
                catch (err){
                    console.log("error settign selectedBu",err)
                }
            }).then(
                () => {
                    setSelectedUnitStored(true)
                }
            )

            let unitList: any = []
            let customerList: any = []
            let siteList: any = []
            for (const accessAllowed of response.data[0]["accessAllowed"]) {
                unitList = unitList.concat(accessAllowed["units"])
                customerList.push(accessAllowed["customerId"])
                siteList.push(accessAllowed["sitesId"])
            }

            const unitQuotes = unitList.map((unit: any) => `"${unit}"`);
            const unitsToQuery = unitQuotes.join(',')

            const sitesQuotes = siteList.map((site: any) => `"${site}"`);
            const sitesToQuery = sitesQuotes.join(',')

            const customerQuotes = customerList.map((customer: any) => `"${customer}"`);
            const customerToQuery = customerQuotes.join(',')

            localStorage.setItem(LS_PREFIX + ".unitsAllowed", JSON.stringify(unitList));

            api_request(get_customer_relation_api, "POST", JSON.parse(`{"where":{"id":{"inq":[${customerToQuery}]}},"include":{"relation":"sites","scope":{"where":{"id":{"inq":[${sitesToQuery}]}},"include":{"relation":"units","scope":{"where":{"id":{"inq":[${unitsToQuery}]}}}}}}}`),headers)
                .then((response: any) => {
                    let customersWithUnits: any = response.data["result"].filter(
                        (item: any) => {
                            let siteHasUnits: Boolean = true;
                            item.sites.forEach(
                                (site: any) => {
                                    if (site.units.length === 0)
                                        siteHasUnits = false
                                }
                            )
                            return siteHasUnits;
                        }
                    )
                    customerAllowed = {
                        customer: customersWithUnits,
                        lastSetTime: new Date(),
                        selectedUnit: selectedUnitId,
                    };
                    localStorage.setItem(LS_PREFIX + ".customerAllowed", JSON.stringify(customerAllowed));
                    record_user_activity(email, accesstoken)
                }).then(
                    () => {
                        // window.open(BASE_URL + "/pulse-master/my-tasks", "_self");
                        setCustAllowedStored(true)
                        setShowLoading(false);
                    }
                );
            setProfileStored(true);
        })
    }

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validate: (values) => {
            const errors: any = {};
            const emailError = validateEmail(values.email);
            if (emailError) {
                errors.email = emailError;
            }
            const passwordError = validatePassword(values.password);
            if (passwordError) {
                errors.password = passwordError;
            }

            return errors;
        },
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: function (values) {
            setShowLoading(true);
            localStorage.clear();
            let path;
            if (process.env.REACT_APP_MODE === 'test') {
                // if(window.location.origin!=='data.exactspace.co' && window.location.origin!=='sandbox.exactspace.co')
                path = 'exactapi/Users/login'
            }
            else if (BASE_URL.includes("exactspace.co") && process.env.REACT_APP_MODE !== 'test') {
                path = 'login'
            }
            else {
                path = 'exactapi/Users/login'
            }
            logEvent("login", { email: values.email });
            
            api_request(`${BASE_URL}/${path}`, "post", values).then((res: any) => {
                if (res.status === 200) {
                    // localStorage.setItem("access_token", res.data.id);
                    const access_token = res.data?.id;
                    const sesson_id = res.data?.sessionId;
                    localStorage.setItem(LS_PREFIX + ".access_token", res.data.id);
                    setError("");
                    if(access_token) {
                        handleLoginUser(access_token, values.email);
                    } else if(sesson_id) {
                        setSessionId(sesson_id);
                        setIs2FACodeRequired(true);
                        setShowLoading(false);
                        // setIs2FA(true);
                    }
                }
                else {
                    if (res.status === 429) {
                        setError("Login failed! Please try resetting your password.");
                    }
                }
            }).catch((error) => {
                setShowLoading(false);
                logException(error,{ email: values.email });
                console.log(error);
                if (error.response && error.response.status === 429) {
                    setError("Login failed! Please try resetting your password.");
                } else {
                    setError("Authentication Failed. Please enter the correct username and password.");
                }
            });
        },
    });

    useEffect(
        () => {

            if (selectedUnitStored && custAllowedStored && profileStored && recordedUserActivity && accessObjStored) {
                setShowLoading(false)

                if(!redirectionURLAccess){
                    localStorage.clear();
                    let anc = document.createElement("a");
                    anc.setAttribute("href", `${BASE_URL}/pulse-login`);
                    anc.click();
                    return;
                }

                // console.log("document?.referrer",document?.referrer);
                // const previousWindowUrl = LS_PREFIX === 'ongc' ? BASE_URL + "/pulse-boilers": BASE_URL + "/pulse-master/my-tasks";
                // const previousWindowUrl = document?.referrer || (LS_PREFIX === 'ongc' ? BASE_URL + "/pulse-boilers": (redirectionURL ? BASE_URL + redirectionURL :  BASE_URL + "/pulse-master/my-tasks"));
                const previousWindowUrl = (redirectionURL ? BASE_URL + redirectionURL :  BASE_URL + "/pulse-master/my-tasks");
                console.log('login success',previousWindowUrl);
                if(isUrlNotAllowed(previousWindowUrl))
                    window.open((redirectionURL ? BASE_URL + redirectionURL :  BASE_URL + "/pulse-master/my-tasks"), "_self");
                else  
                    window.open(previousWindowUrl, "_self");
            }

        }, [selectedUnitStored, custAllowedStored, profileStored, recordedUserActivity, accessObjStored,redirectionURL]
    )
    function togglePassword() {
        setShowPassword(!showPassword);
    }
    useEffect(() => {
        if (
            formik.errors.password === undefined &&
            formik.errors.email === undefined
        ) {
            setError(error);
        } else {
            setError("");
        }
    }, [formik.errors]);

    return (
        <div className="row scroll height-100">
            <D3Visualization />
            <div className="position-relative z-200 form-signin">            
            <div className=" bg-white-light text-black p-0 logincomp">
                {
                    LS_PREFIX.length > 0 ?
                        (
                            LS_PREFIX === 'bhel' ? <div style={{
                                height: "100%",
                                width: "100%",
                                backgroundImage: `url(${bhelLogo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }} className="bg-image"></div>
                                :<></>
                        ):<></>
                }
            </div>
            <div className="text-black">
                <div className="p-4 loginModule">

                    { is2FACodeRequired ? 
                    // {(is2FA && is2FACodeRequired) ? 
                        <TwoFactorAuth 
                            handleSubmit={handleLoginUser}
                            LS_PREFIX={LS_PREFIX}
                            prevValues={formik.values}
                            sessionId={sessionId}
                            hideOTPBox={() => setIs2FACodeRequired(false)}
                        /> 
                        :
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row mb-4">
                            <LogoView LS_PREFIX={LS_PREFIX}/>
                            </div>
                            <h4 className="mb-2 left-0 ">Login</h4>
                            <p className="paraFgt">Use your registered email to continue </p>
            
                                
                            {/* <label>Email</label> */}
                        
                            <div className="relative">
                                <div className="input-group mt-1 mb-4">
                                    <span className="input-group-text">
                                        <i className="fa fa-user"></i>
                                    </span>
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                </div>

                                <div className="error top-40 leftN">
                                    {formik.touched.email && formik.errors.email && (
                                        <span className="size-a text-danger left-0">
                                            {formik.errors.email}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className={`row ${is2FA ? 'mb-3': ''}`}>
                                {/* <label> Password</label> */}
                                <div className="relative">
                            
                                    <div className="input-group mt-1 mb-2">
                                        <span className="input-group-text">
                                            <i className="fa fa-lock"></i>
                                        </span>
                                        <input
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            placeholder="Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />

                                        <div className="input-group-btn pwcont">
                                            <span className="input-group-text">
                                                {showPassword ? (
                                                    <i role="button" className="fa fa-eye-slash" onClick={togglePassword}></i>
                                                    
                                                    
                                                ) : (
                                                
                                                    <i role="button" className="fa fa-eye" onClick={togglePassword}></i>
                                                )}
                                            </span>

                                        </div>
                                    
                                    </div>
                                    <div className="error d-inline-block">
                                        {formik.touched.password && formik.errors.password && (
                                            <span className="size-a text-danger">
                                                {formik.errors.password}
                                            </span>
                                        )}
                                        <div className="size-a text-danger">{error}</div>
                                    </div>
                                </div>

                                <div className="pt-1 mb-0 pb-0 w-100 text-end z-2">
                                    <a
                                        role="button"
                                        className="size-a text-primary "
                                        onClick={() => navigate("/pulse-login/forgot-password")}>
                                        Forgot Password
                                    </a>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={`px-5 btn btn-md btn-primary btn-block mt-3 w-100 position-relative ${showLoading ? 'disabled' : ''}`}
                                >
                                {showLoading ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '24px' }}>
                                    <Loader
                                        loaded={!showLoading}
                                        lines={10}
                                        length={10}
                                        width={5}
                                        radius={10}
                                        corners={1}
                                        rotate={0}
                                        color="#fff"
                                        speed={1}
                                        trail={60}
                                        shadow={false}
                                        hwaccel={false}
                                        className="spinner"
                                        scale={0.5}
                                        loadedClassName=""
                                    />
                                    </div>
                                ) : (
                                    "Login"
                                )}
                            </button>

                        </form>
                    }
                      
                </div>
            </div>
            </div>

        </div>
    );
};

export default Login;