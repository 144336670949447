export const DOMAIN_URL = "http://40.87.97.50"
export const API_HEADERS = { 'Content-Type': 'application/json' }
export const API_LOOKUP = {
  "EXACTAPI": "https://data.exactspace.co/exactapi",
  "SENSORDATA": "https://data.exactspace.co/sensordata",
  "EXACTAPI_DEV": "https://data.exactspace.co/exactapi",
  "SENSORDATA_DEV": "https://data.exactspace.co/sensordata",
}

export const CHAT_INPUT = {
  taskId: "123ABC",
  commentedBy: "Ravi",
  message: "message",
  getmessage: "getMessages",
};

export const CHAT_POPOVER = {
  location: `http://${window.location.hostname}:4105`,
};

export const TEAM_LOOKUP = [
  "Operations",
  "Maintenance",
  "C&I",
  "Electrical",
  "Management",
  "Lab",
];

export const STATUS_LIST = ["Not Started", "In Progress", "Done", "Deferred"]

export const PRIORITY_LIST = ["High", "Medium", "Low"]

export const PRIORITY_COLOR_MAP: any = {
  High: "#FF2E19",
  Medium: "#FF7119",
  Low: "#FFDC19",
  "--":"#E3EDF1"
};

export const PRIORITY_MAP_LOOKUP: any = {
  critical: "Critical",
  high: "High",
  medium: "Medium",
  low: "Low",
  notstarted: "Not started",
};