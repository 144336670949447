import { FormikValues, useFormik } from 'formik';
import React, { useState } from 'react'
import LogoView from '../components/LogoView';
import Loader from "react-loader";
import { api_request } from '../layout/footer/utilities/Functions/APILayer';
import { logException } from '../layout/footer/utilities/Functions/ApplicationInsightLogger'; 


interface TwoFactorAuthProps {
    handleSubmit: (accessToken: string, email: string) => void;
    LS_PREFIX: string;
    prevValues: FormikValues;
    sessionId: string;
    hideOTPBox: () => void;
}
const TwoFactorAuth: React.FC<TwoFactorAuthProps> = ({ handleSubmit, LS_PREFIX, prevValues, sessionId, hideOTPBox }) => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [error, setError] = useState("");

    let BASE_URL: any;
    if (process.env.REACT_APP_MODE === "test") {
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    } else {
        BASE_URL = window.location.origin;
    }

    const validateOtp = (otp: any): string | null => {
        if (otp === undefined || otp === null || otp === "") {
            return "OTP is required";
        } else if (isNaN(Number(otp))) {
            return "Invalid OTP";
        }
        return null;
    };

    const formik = useFormik({
        initialValues: { otp: "" },
        validate: (values) => {
            const errors: any = {};

            const otpError = validateOtp(values.otp);
            if(otpError) errors.otp = otpError;

            return errors;
        },
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: function (values) {
            setShowLoading(true);
            let path;
            if (process.env.REACT_APP_MODE === 'test') {
                // if(window.location.origin!=='data.exactspace.co' && window.location.origin!=='sandbox.exactspace.co')
                path = 'exactapi/Users/login'
            }
            else if (BASE_URL.includes("exactspace.co") && process.env.REACT_APP_MODE !== 'test') {
                path = 'login'
            }
            else {
                path = 'exactapi/Users/login'
            }
            const payload = {
                email: prevValues.email,
                otp: values.otp,
                sessionId: sessionId
            }
            api_request(`${BASE_URL}/${path}`, "post", payload).then((res: any) => {
                if (res.status === 200) {
                    // localStorage.setItem("access_token", res.data.id);
                    const access_token = res.data?.id;
                    localStorage.setItem(LS_PREFIX + ".access_token", res.data.id);
                    setError("");
                    if(access_token) {
                        handleSubmit(access_token, prevValues.email);
                    }
                }
                else {
                    if (res.status === 429) {
                        setError("Login failed! Please enter correct verification code.");
                    }
                }
            }).catch((error) => {
                setShowLoading(false);
                logException(error,{ email: prevValues.email });
                console.log(error);
                if (error.response && error.response.status === 429) {
                    setError("Login failed! Please try resetting your password.");
                } else {
                    setError("Authentication Failed. Please enter the correct verification code.");
                }
            });
        }
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row mb-4">
                <LogoView LS_PREFIX={LS_PREFIX} />
            </div>
            <p className="paraFgt">Please enter your code to continue </p>

            <div className="row">
                {/* OTP */}
                <div className="relative">
            
                    <div className="input-group mt-1 mb-2">
                        <span className="input-group-text">
                            <i className="fa fa-clock-o"></i>
                        </span>
                        <input
                            id="otp"
                            type={"number"}
                            className="form-control"
                            placeholder="Enter Code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.otp}
                            autoFocus
                        />
                    
                    </div>
                    <div className="error d-inline-block">
                        {formik.touched.otp && formik.errors.otp && (
                            <span className="size-a text-danger">
                                {formik.errors.otp}
                            </span>
                        )}
                        <div className="size-a text-danger">{error}</div>
                    </div>
                </div>
                
                <div className="pt-1 mb-0 pb-0 w-100 text-end z-2">
                    <a
                        role="button"
                        className="size-a text-primary "
                        onClick={() => hideOTPBox()}
                    >
                        Click here
                    </a>
                    <span className='size-a back-to-login'>to go back.</span>
                </div>
            </div>

            <button
                type="submit"
                className={`px-5 btn btn-md btn-primary btn-block mt-3 w-100 position-relative ${showLoading ? 'disabled' : ''}`}
            >
                {showLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '24px' }}>
                        <Loader
                            loaded={!showLoading}
                            lines={10}
                            length={10}
                            width={5}
                            radius={10}
                            corners={1}
                            rotate={0}
                            color="#fff"
                            speed={1}
                            trail={60}
                            shadow={false}
                            hwaccel={false}
                            className="spinner"
                            scale={0.5}
                            loadedClassName=""
                        />
                    </div>
                ) : (
                    "Submit"
                )}
            </button>

        </form>
    )
}

export default TwoFactorAuth