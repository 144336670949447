import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Pulse.svg";
import bhelLogo from  "../assets/images/bhelupdated.jpg";

import { api_request } from "../layout/footer/utilities/Functions/APILayer";
import Loader from "react-loader";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import D3Visualization from "./D3Visualization";
import OngcLogo from "../assets/images/ongc_logo.png";
import LogoView from "../components/LogoView";

const ResetPasswordScreen = () => {

    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    function togglePassword() {
        setShowPassword((prev: any) => !prev);
    }

    const validatePassword = (password:any) => {
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return passwordRegex.test(password);
    };

    let BASE_URL: any;
    if (process.env.REACT_APP_MODE === "test") {
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    } else {
        BASE_URL = window.location.origin;
    }
    const [LS_PREFIX, setLSPrefix] = useState("");
    useEffect(() => {
        api_request(`${BASE_URL}/ls-prefix`, "get")
            .then((response: any) => {
                setLSPrefix(response.data[0]["LS_PREFIX"])
            })
    }, [])
    const resetPassword = () => {
        if (!password) {
            setError("Password cannot be empty!");
            return;
        }
        if (!validatePassword(password)) {
            setError("Password must be 8+ chars with at least one special character, number, uppercase & lowercase letter.");
            return;
        }
        setShowLoading(true);
        const oldToken:string = queryParams.get('access_token') || "";
        if(!oldToken) {
            console.log("no token found");
            return;
        }
        api_request(BASE_URL + `/exactapi/Users/reset-password?access_token=${oldToken}`, "post", { newPassword: password })
            .then((res: any) => {
                api_request(BASE_URL+`/exactapi/Users/logout?access_token=${oldToken}`, "post", {})
                .then(() => {})
                .catch(() => {})
                .finally(() => {
                setError("")
                navigate("/pulse-login")
            });
            }).catch((error) => {
                setError("Link expired. Please retry");
                setShowLoading(false);
            });
    };
    console.log("LS_PREFIX", LS_PREFIX)
    return (
        <div className="row scroll height-100">
              <D3Visualization />

            <div className="position-relative z-200 form-signin">
            <div >
                {/* <img src={Logo} width="290" alt="logo" /> */}
                {
                    LS_PREFIX.length > 0 ? 
                    // (
                        // LS_PREFIX !== 'bhel' ? <img src={bhelLogo} width="290" height="200" alt="logo" /> :
                        //     <img src={Logo} width="290" alt="logo" />) : <></>
                        (
                            LS_PREFIX === 'bhel' ? <div style={{ height: "100%",
                                width: "100%",
                                backgroundImage: `url(${bhelLogo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',}} className="bg-image"></div>: <></>
                            
                        )
                        :
                            ""

                }
            </div>
            <div>
                <div className="p-4 loginModule">

                    <div className="row mb-4">
                        <LogoView LS_PREFIX={LS_PREFIX}/>
                    </div>

                    <h4 className="mb-2">Change your password</h4>
                    <p className="mb-4 paraFgt text-left pl-0 text-sm">Choose a strong password that is unique and not used on any other accounts. </p>
                    <div className="relative">
                        <div className="input-group mt-1 mb-4 pwcont ">
                            <span className="input-group-text rounded-start">
                                <i className="fa fa-lock"></i>
                            </span>
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                onChange={(e: any) => {
                                    setPassword(e.target.value);
                                }}
                            />

                            <div className="input-group-btn">
                                <span className="input-group-text">
                                    {showPassword ? (
                                        <i role="button" className="fa fa-eye-slash"  onClick={togglePassword}></i>
                                           
                                        
                                    ) : (
                                       
                                        <i role="button" className="fa fa-eye" onClick={togglePassword}
                                        ></i>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    <button
                        type="reset"
                        className="px-5 btn btn-md btn-primary btn-block mt-3 w-100 position-relative "
                        onClick={resetPassword}
                    >
                        Reset
                    </button>
                    <div className="mt-2 text-danger">{error}</div>
                    <div className="col-md-12 mt-2 text-center">
                        <a
                            role="button"
                            className="size-b text-primary pointer size-a"
                            onClick={() => navigate("/pulse-login")}
                        >
                            Go to log-in
                        </a>
                    </div>
                </div>
            </div>
            </div>


            {/* <Loader
                loaded={!showLoading}
                lines={10}
                length={10}
                width={5}
                radius={10}
                corners={1}
                rotate={0}
                direction={1}
                color="#000"
                speed={1}
                trail={60}
                shadow={false}
                hwaccel={false}
                className="spinner"
                top="73%"
                left="82%"
                scale={0.5}
                loadedClassName="loadedContent"
            /> */}
        </div>
    );
};

export default ResetPasswordScreen;
