import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { setLSPREFIX } from "./lsPrefix";
import { 
  AsanaErrorLog, 
  ErrorLocation, 
  ErrorResponseData 
} from "../Types/errorLogger.types";
import { getBasePath } from "../../../navbar/config/config";

// Constants
const ASANA_CONFIG = {
  TASK_CREATOR_URL: "https://data.exactspace.co/asana/add-tasks",
  ALLOWED_LS_PREFIXES: ["prod", "cars", "utcl", "lpg", "hrd","bpcl"] as const,
} as const;

/**
 * Sends error logs to Asana
 * @param errorLog - Structured error log object
 * @returns Promise that resolves when the log is sent
 */
const logToAsana = async (errorLog: AsanaErrorLog): Promise<void> => {
  try {
    if(!Object.keys(errorLog).length) return;
    await axios.post(ASANA_CONFIG.TASK_CREATOR_URL, errorLog).catch((err)=>console.log(err));
  } catch (error) {
    console.warn("Failed to create task on Asana:", error);
  }
};

/**
 * Extracts request payload from axios config
 * @param config - Axios request configuration
 * @returns Extracted payload or 'No payload' string
 */
const extractPayload = (config: AxiosRequestConfig): unknown => {
  if (!config) return "No payload";

  if (config.method?.toUpperCase() === "GET") {
    if (config.params) return config.params;
    
    if (config.url) {
      try {
        const url = new URL(config.url, window.location.origin);
        return Object.fromEntries(url.searchParams);
      } catch {
        return "No payload";
      }
    }
  }
  
  return config.data || "No payload";
};

/**
 * Extracts error message from response data
 * @param data - Response data object
 * @returns Extracted error message or default message
 */
const extractErrorMessage = (data: ErrorResponseData | undefined): string => {
  if (!data) return "Unknown error";
  return data.message || data.error?.message || "Unknown error";
};

/**
 * Checks if error logging should proceed based on environment
 * @param ls_prefix - Local storage prefix
 * @param location - Current location information
 * @returns Boolean indicating if logging should proceed
 */
const shouldLogError = (ls_prefix: string, location: ErrorLocation): boolean => {
  const isLocalOrSandbox =  location.hostname === "sandbox.exactspace.co" || location.hostname === "localhost";
  const isAllowedPrefix = ASANA_CONFIG.ALLOWED_LS_PREFIXES.includes(ls_prefix as any);
  return !isLocalOrSandbox && isAllowedPrefix;
};

/**
 * Handles and logs API and UI errors to Asana
 * @param error - The error object to be logged
 */
export function logError(error: AxiosError | Error): void {
  try {
    const ls_prefix = setLSPREFIX(true);
    const location: ErrorLocation = {
      hostname: window.location.hostname,
      url: window.location.href || "Unknown URL"
    };

    if (!shouldLogError(ls_prefix, location)) {
      console.log("skipped error logging for error",error);
      return;
    };

    const baseErrorInfo = {
      ls_prefix,
      url: location.url,
      error_type: error.name || "Unknown Error Type",
      source_file: error.stack?.split("\n")[1]?.trim() || "Unknown source file"
    };

    if (axios.isAxiosError(error)) {
      // Skip logging for Asana task creation errors
      if (error.config?.url?.includes("asana/add-tasks")) return;
      if (error.code === 'ECONNABORTED' || error.message.includes('Network Error')) {
        console.log("Network error occurred; skipping error log.");
        return;
      }

      const status:any = error.response?.status || "Unknown";
      // if (!isNaN(status) || status < 400 || status >= 504) return;
      const endpoint = error.config?.url?.split("?")[0]?.replace(/^(https?:\/\/)?[^/]+/, "") ||  error.config?.url || "Unknown";
      const apiErrorLog: AsanaErrorLog = {
        issue_type: "api",
        description: `API failed with error ${error.code}\nMethod:${error.config?.method?.toUpperCase() || "Unknown"}`,
        ...baseErrorInfo,
        endpoint,
        response: {
          status,
          message: extractErrorMessage(error.response?.data as ErrorResponseData)
        },
        payload: extractPayload(error.config!)
      };
      void logToAsana(apiErrorLog);
    } else {
      const relative_url:string = <string>getBasePath(window.location.href)
      const uiErrorLog: AsanaErrorLog = {
        issue_type: "ui",
        relative_url,
        description: error.message || "Unknown UI error",
        ...baseErrorInfo
      };
      void logToAsana(uiErrorLog);
    }
  } catch (error) {
    console.error("Failed to log error:", error);
  }
}