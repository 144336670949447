import { useState, useEffect } from "react";

import bhelLogo from "../assets/images/bhelupdated.jpg";
import { useNavigate } from "react-router-dom";
import { api_request } from "../layout/footer/utilities/Functions/APILayer";
import D3Visualization from "./D3Visualization";
import LogoView from "../components/LogoView";

const ForgotPasswordScreen = () => {
    const navigate = useNavigate();
    const [email, setemail] = useState("");
    const [error, setError] = useState("");
    const [sucess, setSucess] = useState("");
    let BASE_URL: any;
    if (process.env.REACT_APP_MODE === "test") {
        BASE_URL = process.env.REACT_APP_DOMAIN_URL_PROD;
    } else {
        BASE_URL = window.location.origin;
    }
    const [LS_PREFIX, setLSPrefix] = useState("");
    useEffect(() => {
        api_request(`${BASE_URL}/ls-prefix`, "get")
            .then((response: any) => {
                setLSPrefix(response.data[0]["LS_PREFIX"])
            })
    }, [])

    const ResetPassword = () => {
        setError("");
        if (email)
            api_request(BASE_URL + `/exactapi/Users/reset`, "post", { email: email })
                .then((res: any) => {
                    if (res.status === 204) {
                        setSucess(
                            "Reset password link sent to your registered email address."
                        );
                        setError("");
                    }
                })
                .catch((error) => {
                    setSucess("Reset password link sent to your registered email address.");
                    // alert("login failed");
                });
        else {
            setError("Please enter the email.");
        }
    };

    return (
        <div className="row scroll height-100">
            <D3Visualization />

            <div className="position-relative z-200 form-signin">
                <div className="text-black p-0">
                    {/* <img src={Logo} width="290" alt="logo" /> */}
                    {
                        LS_PREFIX.length > 0 ?
                            // (
                            // LS_PREFIX !== 'bhel' ? <img src={bhelLogo} width="290" height="200" alt="logo" /> :
                            //     <img src={Logo} width="290" alt="logo" />) : <></>
                            (
                                LS_PREFIX === 'bhel' ? <div style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundImage: `url(${bhelLogo})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }} className="bg-image"></div> : 
                                // <img src={Logo} width="290" alt="logo" />
                                <></>

                            )
                            :
                            ""
                    }
                </div>
                <div className="text-black">
                    <div className="p-4 loginModule">
                        <div className="row mb-4">
                        <div className="row mb-4">
                           <LogoView LS_PREFIX={LS_PREFIX}/>
                        </div>
                        </div>
                        {/* <form> */}
                        <h4 className="mb-2 left-0 ">Forgot your password</h4>
                        <p className="paraFgt">Please enter the email address you'd like your password reset information sent to</p>
                        <div className="relative">
                            <div className="input-group mt-1 mb-4">
                                <span className="input-group-text">
                                    <i className="fa fa-user"></i>
                                </span>
                                <input
                                    id="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    onChange={(e: any) => {
                                        console.log(e.target.value);
                                        setError(error);
                                        setemail(e.target.value);
                                    }}
                                    // onBlur={formik.handleBlur}
                                    value={email}
                                />
                            </div>

                            <div className="error leftN">
                                {error && <span className="size-a text-danger ">{error}</span>}
                                {sucess && <span className="size-a text-black">{sucess}</span>}
                            </div>
                        </div>

                        <button
                            type="reset"
                            className=" px-5 btn btn-md btn-primary btn-block mt-3 w-100 "
                            onClick={ResetPassword}
                        >
                            Reset
                        </button>
                        <div className="mt-2 text-center">
                            <a
                                role="button"
                                className="size-b text-primary pointer size-a"
                                onClick={() => navigate("/pulse-login")}
                            >
                                Go to log-in
                            </a>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ForgotPasswordScreen;
