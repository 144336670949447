import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { setLSPREFIX } from "./lsPrefix";
import { getBasePath } from "../../../navbar/config/config";

const reactPlugin = new ReactPlugin();
let appInsights: any = null;

const hostname = window.location.hostname;
let instrumentationKey: string | null = null;

if (hostname === "data.exactspace.co") {
  instrumentationKey = "3c943e15-478e-4151-86bf-6cb9953958a3";
}
/**
 * Initializes Application Insights with the provided instrumentation key.
 * @param {string} instrumentationKey - The Application Insights instrumentation key.
 */

interface TrackPageViewPayloadType {
  accountId?:string,
  name:string,
  authenticatedId:string
}

export const initializeAppInsights = () => {
  if (!instrumentationKey) {
    console.log("Skipped page views, no errors.");
    return;
  }
  const config:any = {
    instrumentationKey,
    extensions: [reactPlugin],
    enableDebug: true,
    loggingLevelConsole: 1, // Errors and warnings will be logged to console
    enableAutoRouteTracking: true,
    disableAutoExceptionLogging: true,
    // 🔴 Disable automatic AJAX & Fetch tracking (to reduce excess logs)
    disableAjaxTracking: true,
    disableFetchTracking: true,

    // 🔴 Disable automatic error tracking
    disableExceptionTracking: true,

    // 🔴 Disable tracking dependencies (requests, external calls)
    disableCorrelationHeaders: true,
  };
  const simplified_url_instead_of_with_params = getBasePath(
    window.location.href
  );
  let trackPageViewPayload:TrackPageViewPayloadType = {
    name: simplified_url_instead_of_with_params,
    authenticatedId: "",
  };
  try {
    const LS_PREFIX = setLSPREFIX(true);
    let profile: { id: string; email: string } = JSON.parse(
      localStorage.getItem(LS_PREFIX + ".profile") || "{}"
    );
    if (profile.id) {
      config["accountId"] = profile.id;
      trackPageViewPayload["accountId"] = profile.id;
    }
    if (profile.email) {
      config["authenticatedId"] = profile.email;
      trackPageViewPayload["authenticatedId"] = profile.email;
    }
  } catch (err) {
    console.log("failed to parse profile", err);
  }
  appInsights = new ApplicationInsights({ config });
  appInsights.loadAppInsights();
  appInsights.trackPageView(trackPageViewPayload);
  console.log("Application Insights initialized.", appInsights);
};

/**
 * Logs a custom event to Application Insights.
 * @param {string} eventName - Name of the event to log.
 * @param {object} [properties] - Additional properties to attach to the event.
 */
export const logEvent = (eventName:string, properties = {}) => {
  if (!appInsights) {
    console.warn(
      "Application Insights is not initialized. Call initializeAppInsights first."
    );
    return;
  }
  appInsights.trackEvent({ name: eventName }, properties);
  appInsights.flush();
};

/**
 * Logs an exception to Application Insights.
 * @param {Error} error - The error to log.
 * @param {object} [properties] - Additional properties to attach to the exception.
 */
export const logException = (error:Error, properties = {}) => {
  if (!appInsights) {
    console.warn(
      "Application Insights is not initialized. Call initializeAppInsights first."
    );
    return;
  }
  appInsights.trackException({ exception: error, properties });
};

/**
 * Logs a trace message to Application Insights.
 * @param {string} message - The message to log.
 * @param {object} [properties] - Additional properties to attach to the trace.
 */
export const logTrace = (message:any, properties = {}) => {
  if (!appInsights) {
    console.warn(
      "Application Insights is not initialized. Call initializeAppInsights first."
    );
    return;
  }
  appInsights.trackTrace({ message }, properties);
};

export { reactPlugin, appInsights };
