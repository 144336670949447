import React from 'react'
import bhelLogo from "../assets/images/bhelupdated.jpg";
import bhelLoginLogo from "../layout/navbar/assets/images/bhelLogo.jpg";
import OngcLogo from "../assets/images/ongc_logo.png";
import Logo from "../Pulse.svg";
import { Skeleton } from '@mui/material';
const LogoView = ({LS_PREFIX=""}:any) => {
    return (
        <>
            {LS_PREFIX.length>0 ? <>
                <div className="col-8 text-left">
                    <img src={LS_PREFIX === 'bhel' ? bhelLoginLogo : Logo} height="50" alt="customerlogo" />
                </div>
                {
                    LS_PREFIX === 'ongc' && // ONGC logo comes here
                    <div className="col-4">
                        <img src={OngcLogo} height="33" alt="customerlogo" className="pull-right" />
                    </div>
                }
            </>
            :
            <div className="">
                <Skeleton height={70} className='w-50 ml-2' animation="pulse"/>
            </div>
            }

        </>
    )
}

export default LogoView